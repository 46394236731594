import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  TextField,
  Typography,
  Grid,
  Box,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Checkbox,
  Collapse,
} from "@mui/material";
import apiService from "../services/apiService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { useNavigate } from "react-router-dom";

const CreateInverterDialog = ({
  isOpen,
  onClose,
  plantId,
  plant,
  setSuccess = null,
  navigateToInverter = true,
  duplicate = false,
  duplicateInverterId = null,
}) => {
  const navigate = useNavigate();

  const [inverterDropdown, setInverterDropdown] = useState({});
  const [optionalDataOpen, setOptionalDataOpen] = useState(false);

  const [newInverter, setNewInverter] = useState({
    // Initialize with default values for your inverter form fields
    name: "",
    manufacturer: "",
    manufacturer_custom: "",
    type: "",
    type_custom: "",
    category: "",
    mounting: "",
    ac_power: "",
    dc_power: "",
    commissioning_date: "",
  });

  const [inverterCreateDialogErrors, setInverterCreateDialogErrors] = useState(
    {}
  );

  // Fetch inverter types for the dropdown
  useEffect(() => {
    const fetchInvertTypes = async () => {
      try {
        const response = await apiService.getInverterTypes();

        if (response && response.hasOwnProperty("types")) {
          setInverterDropdown(response.types);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching InverterTypes:", error);
      }
    };

    fetchInvertTypes();
  }, []);

  const handleInverterInputChange = (e) => {
    /*
    This function gets called each time the input of a
    Textfield gets changed. It changes the stored value for 
    a field to the input of the textfield
    */

    const { name, value } = e.target;

    // Changes the manufacturer and type from text to dropdown
    // if the value of the text was manually changed to empty
    if (name === "manufacturer_custom" && !value) {
      setNewInverter((prevInverter) => ({
        ...prevInverter,
        manufacturer: "",
        manufacturer_custom: "",
        type: "",
        type_custom: "",
      }));
    }
    if ((name === "type_custom" && !value) || name === "manufacturer") {
      setNewInverter((prevInverter) => ({
        ...prevInverter,
        type: "",
        type_custom: "",
      }));
    }

    setNewInverter((prevInverter) => ({
      ...prevInverter,
      [name]: value,
    }));
  };

  const handleCreateInverter = async () => {
    /*
    Creates a new inverter with the data from the form
    */
    const formIsReady = checkInverterCreateDialogErrors();
    if (formIsReady) {
      try {
        // Filter out properties with empty strings
        const nonEmptyProperties = Object.fromEntries(
          Object.entries(newInverter).filter(([key, value]) => value !== "")
        );

        // the is_second_hand flag must be boolean while mui wants "on"/"off"
        if (nonEmptyProperties.is_second_hand === "on") {
          nonEmptyProperties.is_second_hand = true;
        } else {
          nonEmptyProperties.is_second_hand = false;
        }

        if (duplicate) {
          handleCreateDuplicateInverter(nonEmptyProperties);
        } else {
          handleCreateInverterNormal(nonEmptyProperties);
        }
      } catch (error) {
        console.error("Error creating inverter:", error);
      } finally {
        return;
      }
    } else {
      console.log("There are errors in the form");
      console.log(inverterCreateDialogErrors);
    }
  };

  const handleCreateInverterNormal = async (nonEmptyProperties) => {
    // Uses the API to create an inverter
    const response = await apiService.createInverter(
      plantId,
      nonEmptyProperties
    );

    // Check if the creation was successful
    if (
      response &&
      response.hasOwnProperty("message") &&
      response.hasOwnProperty("id")
    ) {
      // Optionally, you can update the inverters state with the new inverter
      // setInverters((prevInverters) => [...prevInverters, response.inverter]);
      if (navigateToInverter) {
        navigate(`/plants/${plantId}/inverter/${response.id}`);
      }
    } else {
      console.error("Invalid response format. 'message' property not found.");
    }
    onClose();
  };

  const handleCreateDuplicateInverter = async (nonEmptyProperties) => {
    /* 
    This function is used to create an inverter with new properties
    but the same strings as another inverter
    It is needed for the replacement functionality
    */

    const stringResponse = await apiService.getStrings(
      plantId,
      duplicateInverterId
    );
    let strings;
    if (stringResponse && stringResponse.hasOwnProperty("strings")) {
      strings = stringResponse.strings;
    } else {
      console.error("Error fetching strings");
      return;
    }

    const response = await apiService.createInverter(
      plantId,
      nonEmptyProperties
    );

    if (
      response &&
      response.hasOwnProperty("message") &&
      response.hasOwnProperty("id")
    ) {
      // Optionally, you can update the inverters state with the new inverter
      // setInverters((prevInverters) => [...prevInverters, response.inverter]);
      console.log(response.message);
    } else {
      console.error("Invalid response format. 'message' property not found.");
      return;
    }

    // Duplicate all strings
    for (let index in strings) {
      let string = strings[index];
      let newString = { ...string };
      await apiService.createString(plantId, response.id, newString);
    }

    setSuccess(true);

    if (navigateToInverter) {
      navigate(`/plants/${plantId}/inverter/${response.id}`);
    }
    onClose();
  };

  const checkInverterCreateDialogErrors = () => {
    /*
    Checks if the input of the form values are valid.
    Sets the error below the fields if it is not the case.
    */

    if (newInverter.manufacturer_custom) {
      newInverter.manufacturer = newInverter.manufacturer_custom;
    }
    if (newInverter.type_custom) {
      newInverter.type = newInverter.type_custom;
    }

    const errors = {};

    if (newInverter.manufacturer === "-- Manufacturer not on the list --") {
      errors.manufacturer = "Manufacturer is required";
      errors.manufacturer_custom = "Manufacturer is required";
    }
    if (newInverter.tpye === "-- Inverter type not on the list --") {
      errors.type = "Type is required";
      errors.type = "Type is required";
    }

    if (!newInverter.name) {
      errors.name = "Inverter name is required";
    }
    if (!newInverter.manufacturer) {
      errors.manufacturer = "Manufacturer is required";
    }
    if (!newInverter.type) {
      errors.type = "Type is required";
    }
    if (!newInverter.category) {
      errors.category = "Category is required";
    }
    if (!newInverter.mounting) {
      errors.mounting = "Place of installation is required";
    }
    if (!newInverter.ac_power) {
      errors.ac_power = "AC power is required";
    } else if (newInverter.ac_power < 0) {
      errors.ac_power = "AC power cannot be negative";
    }
    if (!newInverter.dc_power_manual) {
      errors.dc_power_manual = "DC power is required";
    } else if (newInverter.dc_power_manual < 0) {
      errors.dc_power_manual = "DC power cannot be negative";
    }
    if (!newInverter.commissioning_date) {
      errors.commissioning_date = "Commissioning date is required";
    } else if (
      !newInverter.commissioning_date.match(/^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/)
    ) {
      errors.commissioning_date = "Date not valid";
    } else if (
      (() => {
        const [day, month, year] = newInverter.commissioning_date.split(".");
        const isoDateString = `${year}-${month}-${day}`;
        const date1 = new Date(isoDateString);

        const [day2, month2, year2] = plant.commissioning_date.split(".");
        const isoDateString2 = `${year2}-${month2}-${day2}`;
        const date2 = new Date(isoDateString2);

        const date3 = new Date();

        return date1 < date2 || date1 > date3;
      })()
    ) {
      errors.commissioning_date =
        "Date must be between plant commissioning date and the current date";
    }
    if (newInverter.ac_cable_length < 0) {
      errors.ac_cable_length = "AC cable length cannot be negative";
    }
    if (newInverter.ac_cable_cross_section < 0) {
      errors.ac_cable_cross_section =
        "AC cable cross sectioncannot be negative";
    }
    setInverterCreateDialogErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const changeDialogErrors = (name, value) => {
    /*
    Changes the error value of one property
    */
    setInverterCreateDialogErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Inverter</DialogTitle>
      <DialogContent>
        {/* Form fields for inverter creation */}
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <TextField
              label="Inverter Name" // Title of the TextField
              name="name" // Name for the event-handling
              value={newInverter.name} // Value to show
              placeholder="e.g. Inverter 1" // Value that gets displayed as example
              onChange={handleInverterInputChange} // An event object gets parsed to this function
              fullWidth
              margin="normal"
              error={Boolean(inverterCreateDialogErrors.name)} // Color for the error
              helperText={inverterCreateDialogErrors.name} // Text for the error
              onKeyDown={(e) =>
                e.key === "Enter" ? handleCreateInverter() : null
              }
              onBlur={() => {
                if (!newInverter.name) {
                  changeDialogErrors("name", "Name is required");
                } else {
                  changeDialogErrors("name", "");
                }
              }}
            />
          </Grid>

          {/* Manufacturer */}
          <Grid item xs={12}>
            {newInverter.manufacturer ===
              "-- Manufacturer not on the list --" ||
            newInverter.manufacturer_custom ? (
              <TextField
                label="Inverter Manufacturer"
                name="manufacturer_custom"
                value={newInverter.manufacturer_custom}
                onChange={handleInverterInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterCreateDialogErrors.manufacturer)}
                helperText={inverterCreateDialogErrors.manufacturer}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleCreateInverter() : null
                }
                onBlur={() => {
                  if (!newInverter.manufacturer_custom) {
                    newInverter.manufacturer = "";
                  } else {
                    changeDialogErrors("manufacturer", "");
                  }
                }}
              />
            ) : (
              <TextField
                select
                label="Inverter Manufacturer"
                name="manufacturer"
                value={newInverter.manufacturer}
                onChange={handleInverterInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterCreateDialogErrors.manufacturer)}
                helperText={inverterCreateDialogErrors.manufacturer}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleCreateInverter() : null
                }
                onBlur={() => {
                  if (!newInverter.manufacturer) {
                    changeDialogErrors(
                      "manufacturer",
                      "Manufacturer is required"
                    );
                  } else {
                    changeDialogErrors("manufacturer", "");
                  }
                }}
              >
                {Object.entries(inverterDropdown).map(([key, value]) => (
                  <MenuItem value={key}>{key}</MenuItem>
                ))}
              </TextField>
            )}
          </Grid>

          {/* Type */}
          <Grid item xs={12}>
            {newInverter.manufacturer ===
              "-- Manufacturer not on the list --" ||
            newInverter.manufacturer_custom ||
            newInverter.type === "-- Inverter type not on the list --" ||
            newInverter.type_custom ? (
              <TextField
                label="Inverter Type"
                name="type_custom"
                value={newInverter.type_custom}
                onChange={handleInverterInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterCreateDialogErrors.type_custom)}
                helperText={inverterCreateDialogErrors.type_custom}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleCreateInverter() : null
                }
                onBlur={() => {
                  if (!newInverter.type) {
                    newInverter.type = "";
                  } else {
                    changeDialogErrors("type_custom", "");
                  }
                }}
              />
            ) : (
              <TextField
                select
                label="Inverter Type"
                name="type"
                value={newInverter.type}
                onChange={handleInverterInputChange}
                fullWidth
                margin="normal"
                error={Boolean(inverterCreateDialogErrors.type)}
                helperText={inverterCreateDialogErrors.type}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleCreateInverter() : null
                }
                onBlur={() => {
                  if (!newInverter.type) {
                    changeDialogErrors("type", "Type is required");
                  } else {
                    changeDialogErrors("type", "");
                  }
                }}
              >
                {Object.keys(inverterDropdown).includes(
                  newInverter.manufacturer
                )
                  ? inverterDropdown[newInverter.manufacturer].map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))
                  : null}
              </TextField>
            )}
          </Grid>

          {/* Category */}
          <Grid item xs={12}>
            <Box sx={{ height: 10 }} />
            <TextField
              select
              label="Inverter Category"
              name="category"
              value={newInverter.category}
              onChange={handleInverterInputChange}
              fullWidth
              margin="normal"
              displayEmpty // Add this prop to display the empty value
              error={Boolean(inverterCreateDialogErrors.category)}
              helperText={inverterCreateDialogErrors.category}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleCreateInverter() : null
              }
              onBlur={() => {
                if (!newInverter.category) {
                  changeDialogErrors("category", "Category is required");
                } else {
                  changeDialogErrors("category", "");
                }
              }}
            >
              <MenuItem value={"String Inverter"}>String Inverter</MenuItem>
              <MenuItem value={"Multi String Inverter"}>
                Multi String Inverter
              </MenuItem>
              <MenuItem value={"Central Inverter"}>Central Inverter</MenuItem>
              <MenuItem value={"Micro Inverter"}>Micro Inverter</MenuItem>
              <MenuItem value={"Hybrid Inverter"}>Hybrid Inverter</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </TextField>
          </Grid>

          {/* Place of installation */}
          <Grid item xs={12}>
            <Box sx={{ height: 15 }} />
            <TextField
              select
              label="Place of installation"
              name="mounting"
              value={newInverter.mounting}
              onChange={handleInverterInputChange}
              fullWidth
              margin="normal"
              displayEmpty // Add this prop to display the empty value
              error={Boolean(inverterCreateDialogErrors.mounting)}
              helperText={inverterCreateDialogErrors.mounting}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleCreateInverter() : null
              }
              onBlur={() => {
                if (!newInverter.mounting) {
                  changeDialogErrors("mounting", "Mounting is required");
                } else {
                  changeDialogErrors("mounting", "");
                }
              }}
            >
              <MenuItem value={"Indoor, air conditioned"}>
                Indoor, air conditioned
              </MenuItem>
              <MenuItem
                value={"Indoor, air not conditioned (no risk of overheating)"}
              >
                Indoor, air not conditioned (no risk of overheating)
              </MenuItem>
              <MenuItem
                value={"Indoor, air not conditioned (risk of overheating)"}
              >
                Indoor, air not conditioned (risk of overheating)
              </MenuItem>
              <MenuItem value={"Outdoor, exposed to sun / rain"}>
                Outdoor, exposed to sun / rain
              </MenuItem>
              <MenuItem value={"Outdoor, protected from sun / rain"}>
                Outdoor, protected from sun / rain
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </TextField>
            <Box sx={{ height: 10 }} />
          </Grid>

          {/* AC Power */}
          <Grid item xs={12}>
            <TextField
              label="AC Power in kW"
              name="ac_power"
              value={newInverter.ac_power}
              onChange={handleInverterInputChange}
              type="number"
              fullWidth
              margin="normal"
              error={Boolean(inverterCreateDialogErrors.ac_power)}
              helperText={inverterCreateDialogErrors.ac_power}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kW</InputAdornment>
                ),
              }}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleCreateInverter() : null
              }
              sx={{
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }}
              onBlur={() => {
                if (!newInverter.ac_power) {
                  changeDialogErrors("ac_power", "AC power is required");
                } else if (newInverter.ac_power < 0) {
                  changeDialogErrors("ac_power", "AC power cannot be negative");
                } else {
                  changeDialogErrors("ac_power", "");
                }
              }}
            />
          </Grid>

          {/* DC Power */}
          <Grid item xs={12}>
            <TextField
              label="DC Power in kW"
              name="dc_power_manual"
              value={newInverter.dc_power_manual}
              onChange={handleInverterInputChange}
              type="number"
              fullWidth
              margin="normal"
              error={Boolean(inverterCreateDialogErrors.dc_power_manual)}
              helperText={inverterCreateDialogErrors.dc_power_manual}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kW</InputAdornment>
                ),
              }}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleCreateInverter() : null
              }
              sx={{
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }}
              onBlur={() => {
                if (!newInverter.dc_power_manual) {
                  changeDialogErrors("dc_power_manual", "DC power is required");
                } else if (newInverter.dc_power_manual < 0) {
                  changeDialogErrors(
                    "dc_power_manual",
                    "DC power cannot be negative"
                  );
                } else {
                  changeDialogErrors("dc_power_manual", "");
                }
              }}
            />
          </Grid>

          {/* Commissioning Date */}
          <Grid item xs={12}>
            <TextField
              label="Commissioning Date (DD.MM.YYYY)"
              name="commissioning_date"
              value={newInverter.commissioning_date}
              onChange={handleInverterInputChange}
              fullWidth
              margin="normal"
              error={Boolean(inverterCreateDialogErrors.commissioning_date)}
              helperText={inverterCreateDialogErrors.commissioning_date}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleCreateInverter() : null
              }
              onBlur={() => {
                if (!newInverter.commissioning_date) {
                  changeDialogErrors(
                    "commissioning_date",
                    "Commissioining date is required"
                  );
                } else if (
                  !newInverter.commissioning_date.match(
                    /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
                  )
                ) {
                  changeDialogErrors("commissioning_date", "Date not valid");
                } else if (
                  (() => {
                    const [day, month, year] =
                      newInverter.commissioning_date.split(".");
                    const isoDateString = `${year}-${month}-${day}`;
                    const date1 = new Date(isoDateString);

                    const [day2, month2, year2] =
                      plant.commissioning_date.split(".");
                    const isoDateString2 = `${year2}-${month2}-${day2}`;
                    const date2 = new Date(isoDateString2);

                    const date3 = new Date();

                    return date1 < date2 || date1 > date3;
                  })()
                ) {
                  changeDialogErrors(
                    "commissioning_date",
                    "Date must be between plant commissioning date and the current date"
                  );
                } else {
                  changeDialogErrors("commissioning_date", "");
                }
              }}
            />
          </Grid>

          {/* Second hand */}
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              label="is_second_hand"
              name="is_second_hand"
              onChange={handleInverterInputChange}
              value={newInverter.is_second_hand}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleCreateInverter() : null
              }
            />
            <Typography variant="body1" color="textPrimary">
              Second Hand Inverter
            </Typography>
          </Grid>

          {/* optional data */}
          <Grid item xs={12}>
            <Button
              onClick={() => setOptionalDataOpen(!optionalDataOpen)}
              variant="text"
              sx={{
                backgroundColor: "transparent",
                typography: "body1",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              disableElevation
              disableRipple
              onHover={{}}
              color="inherit"
            >
              {optionalDataOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography variant="h6" style={{ textTransform: "none" }}>
                Optional Information
              </Typography>
            </Button>
          </Grid>

          {/* Optional Infromation */}
          <Grid item xs={12}>
            <Collapse in={optionalDataOpen} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                {/* AC cable length */}
                <Grid item xs={12}>
                  <TextField
                    label="AC cable length in m"
                    name="ac_cable_length"
                    type="number"
                    value={newInverter.ac_cable_length}
                    onChange={handleInverterInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(inverterCreateDialogErrors.ac_cable_length)}
                    helperText={inverterCreateDialogErrors.ac_cable_length}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleCreateInverter() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newInverter.ac_cable_length &&
                        newInverter.ac_cable_length < 0
                      ) {
                        changeDialogErrors(
                          "ac_cable_length",
                          "AC cable length cannot be negative"
                        );
                      } else {
                        changeDialogErrors("ac_cable_length", "");
                      }
                    }}
                  />
                </Grid>

                {/* AC cable cross section */}
                <Grid item xs={12}>
                  <TextField
                    label="AC Cable cross section in mm²"
                    name="ac_cable_cross_section"
                    type="number"
                    value={newInverter.ac_cable_cross_section}
                    onChange={handleInverterInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      inverterCreateDialogErrors.ac_cable_cross_section
                    )}
                    helperText={
                      inverterCreateDialogErrors.ac_cable_cross_section
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm²</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleCreateInverter() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        newInverter.ac_cable_cross_section &&
                        newInverter.ac_cable_cross_section < 0
                      ) {
                        changeDialogErrors(
                          "ac_cable_cross_section",
                          "AC cable cross section cannot be negative"
                        );
                      } else {
                        changeDialogErrors("ac_cable_cross_section", "");
                      }
                    }}
                  />
                </Grid>

                {/* Galvanic Separation */}
                <Grid item xs={12}>
                  <TextField
                    label="Galvanic Separation"
                    name="galvanic_separation"
                    value={newInverter.galvanic_separation}
                    onChange={handleInverterInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      inverterCreateDialogErrors.galvanic_separation
                    )}
                    helperText={inverterCreateDialogErrors.galvanic_separation}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleCreateInverter() : null
                    }
                  />
                </Grid>

                {/* Serial Number */}
                <Grid item xs={12}>
                  <TextField
                    label="Serial Number"
                    name="serial_number"
                    value={newInverter.serial_number}
                    onChange={handleInverterInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(inverterCreateDialogErrors.serial_number)}
                    helperText={inverterCreateDialogErrors.serial_number}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleCreateInverter() : null
                    }
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleCreateInverter} color="primary">
          Add Inverter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateInverterDialog;
