import React, { useState, useEffect } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Checkbox,
} from "@mui/material";
import apiService from "../services/apiService";
import DatePickerComponent from "./DatePicker";

import ShowChartIcon from "@mui/icons-material/ShowChart";

const KaplanMeier = () => {
  const [url, setUrl] = useState(null);

  // Filters
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mounting, setMounting] = useState("All");
  const [onlyOptimizers, setOnlyOptimizers] = useState(false);

  const fetchUrl = async () => {
    try {
      const response = await apiService.getKaplanMeier({
        startDate: startDate,
        endDate: endDate,
        mounting: mounting,
        mustHaveOptimizer: onlyOptimizers,
      });

      if (response) {
        setUrl(response);
      } else {
        console.error("Invalid response format.");
      }
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    fetchUrl();
    // eslint-disable-next-line
  }, [startDate, endDate, mounting, onlyOptimizers]);

  return (
    <Card
      elevation={5}
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          "align-items": "center",
        }}
      >
        {/* Title */}
        <Typography variant="h5">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <ShowChartIcon />
            <Box style={{ marginLeft: "10px" }}>Kaplan Meier Diagramm</Box>
          </Box>
        </Typography>

        {/* Actual Diagram */}
        <CardMedia
          marginTop={3}
          sx={{
            width: { xs: 300, sm: 400, md: 600 }, // sizing for the picture depending on the breakpoints
            height: { xs: 250, sm: 333, md: 500 },
          }}
          image={url}
        />

        <Typography variant="h6" marginTop={3}>
          Filters
        </Typography>

        {/* Start Date */}
        <Grid container spacing={2} marginTop={1}>
          <Grid
            item
            xs={3}
            md={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">Start Date: </Typography>
          </Grid>

          <Grid
            item
            xs={9}
            sm={3}
            md={4}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <DatePickerComponent value={startDate} setValue={setStartDate} />
          </Grid>

          {/* End Date */}
          <Grid
            item
            xs={3}
            md={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">End Date: </Typography>
          </Grid>

          <Grid
            item
            xs={9}
            sm={3}
            md={4}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <DatePickerComponent value={endDate} setValue={setEndDate} />
          </Grid>

          {/* Place of installation */}
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">Place of installation: </Typography>
          </Grid>

          <Grid
            item
            xs={9}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <TextField
              select
              name="mounting"
              value={mounting}
              onChange={(e) => {
                setMounting(e.target.value);
              }}
              fullWidth
              margin="normal"
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Indoor, air conditioned"}>
                Indoor, air conditioned
              </MenuItem>
              <MenuItem
                value={"Indoor, air not conditioned (no risk of overheating)"}
              >
                Indoor, air not conditioned (no risk of overheating)
              </MenuItem>
              <MenuItem
                value={"Indoor, air not conditioned (risk of overheating)"}
              >
                Indoor, air not conditioned (risk of overheating)
              </MenuItem>
              <MenuItem value={"Outdoor, exposed to sun / rain"}>
                Outdoor, exposed to sun / rain
              </MenuItem>
              <MenuItem value={"Outdoor, protected from sun / rain"}>
                Outdoor, protected from sun / rain
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </TextField>
          </Grid>

          {/* Optimizer filter */}
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={onlyOptimizers}
              sx={{ color: "green" }}
              onClick={() => {
                if (!onlyOptimizers) {
                  setOnlyOptimizers(false);
                }
                setOnlyOptimizers(!onlyOptimizers);
              }}
            />
            <Typography variant="body1" align="center" gutterBottom>
              Show only inverter with optimizers attachted to them
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default KaplanMeier;
